body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  clear: both;
  height: 100%;
  font-family: Futura PT, sans-serif;
}
