body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  clear: both;
  height: 100%;
  font-family: Futura PT, sans-serif;
}

:root {
  /* --primary: #1a1a18; */
  --primary: #6c3394;
  /* --hover: #121212; */
  --hover: #6c3394;
  --white: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: "Futura PT", sans-serif;
}

.logo {
  margin: 0px auto;
  display: table;
}

.logo img {
  max-width: 200px;
}

.header {
  position: relative;
}

.nomargin {
  margin: 0;
}

/***************Menu***********************/
.cmn-menu {
  padding: 0;
  list-style: none;
  margin: 0;
  text-align: center;
}

.cmn-menu a {
  background-color: #6c3394;
  background-color: var(--primary);
  color: #ffffff;
  color: var(--white);
  padding: 8px;
  display: inline-block;
  width: 90%;
  /* float: left; */
  margin: 1%;
  border: 2px solid transparent;
}

.cmn-menu a:hover,
.cmn-menu a:focus,
.cmn-menu a.active {
  background-color: #6c3394;
  background-color: var(--hover);
  color: #6c3394;
  color: var(--primary);
  text-decoration: none;
  border-color: #6c3394;
  border-color: var(--primary);
  background-color: #ffffff;
  background-color: var(--white);
}

/*****************Register form************************/
.cmn-container h2 {
  font-size: 1.5em;
  margin: 25px 0;
  color: #6c3394;
  color: var(--primary);
  text-align: center;
}

.cmn-form-field {
  margin: 2em 0;
}

.cmn-form-field label span {
  color: #ff0000;
  margin-right: 2px;
}

.cmn-form-field input,
.cmn-form-field select {
  border-color: #6c3394;
  border-color: var(--primary);
  font-size: 0.9em;
}

.small-txt {
  font-size: 0.9em;
  margin: 10px 0 0;
  color: #6c3394;
  color: var(--primary);
}

.small-txt a {
  font-size: 1em;
}

.chkbox-lbl {
  font-size: 0.9em;
}

/********************Register Button***************************/

.home-bg {
  /* background: linear-gradient(#f2fcf3, #c1e9ef); */
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4)
  );
}

.home2-bg {
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.7)
  );
}
.register-bg {
  /* background: url(../images/register-bg.jpg) no-repeat bottom center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}

.cmn-register-btn {
  background-color: #6c3394;
  background-color: var(--primary);
  padding: 10px 30px;
  color: #ffffff;
  color: var(--white);
  border: 0;
  display: table;
  margin: 30px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.cmn-register-btn:hover,
.cmn-register-btn:focus {
  background-color: #6c3394;
  background-color: var(--hover);
  color: #ffffff;
  color: var(--white);
  text-decoration: none;
}

.cmn-register-btn:disabled,
.cmn-register-btn[disabled] {
  background-color: grey;
}

/*************************About**********************************/
.about-bg {
  /* background: url(../images/about-bg.jpg) no-repeat bottom center; */
  background: radial-gradient(#f2fcf3, #cbe4e9);
  /* background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover; */
}

.abt-event {
  padding: 2em 15px;
}

.abt-event p span {
  font-weight: 600;
}

/**************************Schedule Meeting********************************************/

.abt-event.schedule-content {
  padding: 10em 15px;
}

.schedule-content p {
  font-size: 1.2em;
}

/*************************Coming Soon**********************************/
.countdown-bg.coming-soon-bg {
  height: 100vh;
  position: relative;
}

.coming-soon-content {
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 320px;
  max-width: 500px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  -o-border-radius: 30px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
}

.coming-soon-content .coming-soon-white {
  color: #ffffff;
  color: var(--white);
}

/**************************Agenda********************************/
/* .agenda-bg {
  background: url(../images/coming-soon-bg.jpg) no-repeat bottom center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
} */

.agenda-bg-container {
  /* background: url(../images/bg_agenda.jpg) no-repeat bottom center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}

.abt-event .nav {
  margin-bottom: 2.5em;
}

.abt-event .nav li a {
  color: #6c3394;
  color: var(--primary);
  font-size: 0.9em;
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid var(--white);
  padding: 0;
  margin: 0 20px;
  line-height: 30px;
}

.abt-event .nav li a:hover,
.abt-event .nav li a:focus,
.abt-event .nav li a.active {
  border-color: #6c3394;
  border-color: var(--primary);
}
.abt-event table p {
  margin: 0;
}
.abt-event table p span {
  display: block;
  font-weight: normal;
  font-size: 1em;
}
.abt-event table ul {
  padding-left: 20px;
  margin: 10px 0;
}
.abt-event table tr th {
  text-transform: uppercase;
  font-weight: normal;
  background-color: #e6dce3 !important;
}
.abt-event table tr th.starttm {
  width: 150px;
  text-align: center;
}
.abt-event table tr td {
  border: 0;
}

.abt-event table tr.border {
  border: 2px solid #ffffff !important;
}
.abt-event table tr td.bgcolor {
  background-color: #e6dce3;
}
/**********************Countdown Timer************************************/
/* .countdown-bg {
  background: url(../images/bg.jpg) no-repeat left center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
} */

.countdown-container {
  position: relative;
  /* top: 50%;
	-webkit-transform: translateY(50%);
	-moz-transform: translateY(50%);
	transform: translateY(50%); */
  margin: 5em 0;
}

.clock-item .inner {
  height: 0px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.clock-canvas {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  height: 0px;
  padding-bottom: 100%;
}

.text {
  color: #101918;
  font-size: 3em;
  font-weight: 500;
  margin-top: -45px;
  position: absolute;
  top: 50%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  width: 100%;
}

.text .val {
  font-size: 1em;
  margin: 10px 0;
}

.type-time {
  font-size: 18px;
  margin: 10px 0;
}

/*********************Contact***************************/
.contact-bg {
  /* background: url(../images/contact-bg.jpg) no-repeat bottom center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}

.contact-content {
  padding: 2em 15px;
}

.cmn-queries h2 {
  font-size: 1.2em;
  line-height: 1.5em;
  margin: 20px 0;
  font-weight: bold;
}

.cmn-queries h2 span {
  display: block;
  font-weight: normal;
}

.cmn-queries h2 a {
  display: block;
  font-weight: normal;
}

.cmn-queries h2 a.cmn-link {
  color: #6c3394;
  color: var(--primary);
}

.address-sec {
  /* margin: 5em 0 0; */
}

.address-sec p {
  margin: 0;
}

.address-sec p span {
  font-weight: bold;
}

/********************Footer links***************************/
.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
}

.footer-links li {
  display: inline;
}

.footer-links li a {
  color: #6c3394;
  color: var(--primary);
  font-size: 0.8em;
  padding: 0 5px;
  border-right: 1px solid #6c3394;
  border-right: 1px solid var(--primary);
}

.footer-links li a.last {
  border: 0;
}

/******************Video********************/
.fullwidth-video {
  width: 100%;
}

.custom-video-modal .modal-content {
  background-color: transparent;
  border: 0;
}

.custom-video-modal .modal-header {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  z-index: 9;
}

/************************Scheduler****************************/
.schedule-meeting-wrap {
  padding: 20px 2em;
  border: 1px solid #d3d3d3;
}

.guest-wrap {
  border: 1px solid #d3d3d3;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.checkicon {
  display: none;
}

.guest-wrap p {
  font-size: 1em;
  margin: 0;
}

.guest-wrap i {
  font-size: 1.2em;
}

.guest-wrap:hover,
.guest-wrap:focus,
.guest-wrap.active {
  background-color: #323a49;
  border-color: #323a49;
  color: #ffffff;
  color: var(--white);
}

.guest-wrap:hover .checkicon,
.guest-wrap:focus .checkicon,
.guest-wrap.active .checkicon {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.9em;
}

.guest-container {
  max-height: 200px;
  overflow-y: auto;
}

.abt-event p span.guestnm {
  font-style: italic;
  color: #294579;
}

/*************************************************/
.abt-event .tabs-left {
  border: 0;
  margin: 0;
}
.abt-event .tabs-left li {
  display: block;
  width: 100%;
  margin: 0;
}
.abt-event .tabs-left li a {
  display: block;
  text-align: center;
  color: #294579;
  border: 1px solid #294579;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
}
.abt-event .tabs-left > li.active > a,
.abt-event .tabs-left > li.active > a:hover,
.abt-event .tabs-left > li.active > a:focus {
  border-color: #294579;
  background: #294579;
  color: #ffffff;
  text-decoration: none;
}
.timeslot {
  background-color: #cfd6c9;
  color: #6c3394;
  color: var(--primary);
  padding: 5px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1em;
}
.timeslot i {
  padding-right: 10px;
}

.timeslot:hover,
.timeslot:focus,
.timeslot.active {
  background-color: #67715f;
  color: #ffffff;
  color: var(--white);
}
/******************Confirm Meeting************************/
.confirmmeeting-wrap p {
  font-size: 1.3em;
  color: #6c3394;
  color: var(--primary);
  text-align: center;
}
.confirmmeeting-wrap p span {
  display: block;
  font-weight: normal;
}
.usersicn {
  display: block;
  text-align: center;
  font-size: 3em;
  margin-bottom: 15px;
}
.confirmmeeting-wrap .timeslot {
  display: table;
  padding: 5px 20px;
  margin: auto;
  margin-bottom: 20px;
}
.confirmmeeting-wrap .cmn-register-btn {
  margin: 20px;
  display: inline-block;
  padding: 10px;
}
.confirmmeeting-wrap .back-btn {
  background-color: #c5c3c3;
  color: #6c3394;
  color: var(--primary);
}
.btns-container {
  display: table;
  margin: auto;
}
.toplinks {
  position: absolute;
  top: 60px;
  right: 20px;
}
.toplinks a {
  color: #6c3394;
  color: var(--primary);
}
.toplinks a i {
  font-size: 0.8em;
  padding-right: 2px;
}
.toplinks a.logout {
  color: #67715f;
}
.toplinks a.logout:hover,
.toplinks a.logout:focus,
.toplinks a.logout.active {
  color: #6c3394;
  color: var(--primary);
}
.abt-event table th {
  vertical-align: middle;
}
/****************************************************
    Media Queries
*************************************************/
@media only screen and (max-width: 575px) {
  body {
    font-size: 13px;
  }

  /***********Menu**************/
  .cmn-menu li a {
    width: 48%;
  }

  .cmn-form-field {
    margin: 1em 0;
  }

  /**********************Countdown Timer************************************/
  .clock-item {
    margin: 30px 0;
  }

  .countdown-container {
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
  }

  .countdown-bg {
    height: auto;
  }

  .coming-soon-content {
    max-width: 320px;
  }

  /******************Scheduler***********************/
  .guest-container {
    max-height: none;
  }

  .guest-wrap:hover .checkicon,
  .guest-wrap:focus .checkicon,
  .guest-wrap.active .checkicon {
    bottom: 5px;
  }
  .btns-container {
    display: block;
  }
  .confirmmeeting-wrap .cmn-register-btn {
    margin: 20px auto 10px;
    display: table;
  }
  .abt-event .tabs-left li {
    width: 33.3%;
    margin: 0;
    padding: 10px 5px;
  }
  .abt-event .tabs-left li a {
    margin-bottom: 10px;
    line-height: 1.5em;
    padding: 10px 0;
  }
  .timeslot {
    font-size: 0.8em;
  }
  .timeslot i {
    display: none;
  }
  .toplinks {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    margin: 20px 0;
  }
  .schedule-meeting-wrap .cmn-register-btn {
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
  .schedule-meeting-wrap .cmn-register-btn.back-btn {
    bottom: 38px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  body {
    font-size: 13px;
  }

  /***********Menu**************/
  .cmn-menu li a {
    width: 48%;
  }

  /**********************Countdown Timer************************************/
  .clock-item {
    margin: 30px 0;
  }

  .countdown-container {
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
  }

  .countdown-bg {
    height: auto;
  }

  .coming-soon-content {
    max-width: 320px;
  }

  /******************Scheduler***********************/
  .guest-container {
    max-height: none;
  }

  .guest-wrap:hover .checkicon,
  .guest-wrap:focus .checkicon,
  .guest-wrap.active .checkicon {
    bottom: 5px;
  }

  .abt-event .tabs-left li {
    width: 33.3%;
    margin: 0;
    padding: 10px 5px;
  }
  .toplinks {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    margin: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }

  /***********Menu**************/
  .cmn-menu li a {
    width: 48%;
  }

  /**********************Countdown Timer************************************/
  .clock-item {
    margin-bottom: 30px;
  }

  .text .val {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .guest-wrap p {
    font-size: 0.9em;
  }
}

/* For Customization in otp */
.otp-input {
  width: 100%;
  display: flex;
  justify-content: center;
}
.otp-input input {
  width: 2.4em !important;
  border-radius: 5px;
  padding: 7px;
  border: 3px solid #1a1a18;
}

.otp-input span {
  font-size: 30px;
  font-weight: bold;
}

.home-content-wrapper img {
  width: 100%;
}

.home-authentication {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-authentication .cmn-register-btn {
  background-color: #6c3394;
  background-color: var(--primary);
  padding: 15px 50px;
  color: #ffffff;
  color: var(--white);
  border: 0;
  display: table;
  margin: 30px 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.home-games {
  padding: 30px;
}

.home-games img {
  width: 100%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* Leaderboard */
.leaderboard {
  padding: 30px;
}

.leaderboard .top-score-item {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1), 0 7px 7px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.top-score-item i {
  color: gold;
}

.top-score-item .name {
  text-transform: capitalize;
}

.my-score {
  padding: 20px 0px 50px 0px;
}

.align-center {
  text-align: center;
}

/* .rank {
  font-size: 20px;
} */

.success-animation {
  position: fixed;
  top: 20px;
  right: 10px;
  background-color: green;
  color: white;
  padding: 5px 20px 5px 50px;
  border-radius: 7px;
  z-index: 1;
}

.success-animation .close-notification {
  /* display: inline-block; */
  float: right;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  cursor: pointer;
  margin-left: 20px;
}

.success-animation-none {
  display: none;
}

.page-wrapper canvas {
  position: fixed !important;
  z-index: -1;
  top: 0;
  left: 0;
}

.ar-filter-icon {
  position: relative;
  border-radius: 20px;
}

.ar-filter-icon img {
  border-radius: 30px;
}

.ar-filter-social {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  width: 92%;
  border-radius: 30px;
}

.ar-filter-social i {
  font-size: 80px;
  margin: 0px 10px;
}

.ar-filter-social .facebook {
  color: #3b5998;
}

.ar-filter-social .instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ar-filter-icon:hover .ar-filter-social {
  background-color: #ffffff69;
  display: flex;
  margin: 0px 15px;
}

@media (max-width: 575.98px) {
  .home-authentication {
    flex-direction: column;
  }
  .leaderboard {
    padding: 0px;
  }

  .ar-filter-social {
    background-color: #ffffff69;
    display: flex;
    margin: 0px 15px;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

.main-menu {
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
  left: 10%;
  margin: auto;
  z-index: 999;
}

.main-menu-item {
  border: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  border: 1px solid rgb(109, 108, 108);
  overflow: hidden;
}

.main-menu-item a {
  padding: 10px 0px;
  background-color: #6c3394;
  color: white;
  width: 100%;
  display: inherit;
}

.main-menu-item i {
  margin-right: 10px;
  font-size: 25px;
}

/* Auth menu */
.user-detail-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-detail span {
  font-weight: 900;
  font-size: 20px;
  margin-right: 10px;
}

.user-detail i {
  cursor: pointer;
}

.user-detail {
  position: relative;
}

.user-detail .firstname {
  text-transform: capitalize;
}
/* Auth menu */

/* Custom dropdown menu */
.custom-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  border-radius: 7px;
  padding: 5px 20px;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .main-menu {
    width: 100%;
    left: 0;
  }

  .menu-text {
    display: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-menu {
    width: 100%;
    left: 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.login-wrapper {
  display: flex;
  justify-content: center;
}

.login-phone-field {
  width: 30%;
  margin-left: 30px;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Bold.6f716db7.eot);
    src: url(/static/media/FuturaPT-Bold.6f716db7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Bold.af1d335c.woff2) format('woff2'),
        url(/static/media/FuturaPT-Bold.14343d56.woff) format('woff'),
        url(/static/media/FuturaPT-Bold.a71172d4.ttf) format('truetype'),
        url(/static/media/FuturaPT-Bold.b6257596.svg#FuturaPT-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Medium.9f7e7d19.eot);
    src: url(/static/media/FuturaPT-Medium.9f7e7d19.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Medium.92d2acd7.woff2) format('woff2'),
        url(/static/media/FuturaPT-Medium.c9127523.woff) format('woff'),
        url(/static/media/FuturaPT-Medium.e9e58ef9.ttf) format('truetype'),
        url(/static/media/FuturaPT-Medium.fbc763da.svg#FuturaPT-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Light.659d12e6.eot);
    src: url(/static/media/FuturaPT-Light.659d12e6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Light.8434d0fb.woff2) format('woff2'),
        url(/static/media/FuturaPT-Light.5daf4445.woff) format('woff'),
        url(/static/media/FuturaPT-Light.a93ea3a5.ttf) format('truetype'),
        url(/static/media/FuturaPT-Light.4012fcc7.svg#FuturaPT-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Heavy.936918ff.eot);
    src: url(/static/media/FuturaPT-Heavy.936918ff.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Heavy.798a8357.woff2) format('woff2'),
        url(/static/media/FuturaPT-Heavy.9de4ea23.woff) format('woff'),
        url(/static/media/FuturaPT-Heavy.370b5d63.ttf) format('truetype'),
        url(/static/media/FuturaPT-Heavy.3f3be799.svg#FuturaPT-Heavy) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url(/static/media/FuturaPT-Book.9ae6ae4c.eot);
    src: url(/static/media/FuturaPT-Book.9ae6ae4c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Book.47a35540.woff2) format('woff2'),
        url(/static/media/FuturaPT-Book.c96bdda5.woff) format('woff'),
        url(/static/media/FuturaPT-Book.636a8033.ttf) format('truetype'),
        url(/static/media/FuturaPT-Book.402c9f57.svg#FuturaPT-Book) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


