@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Bold.eot');
    src: url('../fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('../fonts/FuturaPT-Bold.woff') format('woff'),
        url('../fonts/FuturaPT-Bold.ttf') format('truetype'),
        url('../fonts/FuturaPT-Bold.svg#FuturaPT-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Medium.eot');
    src: url('../fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('../fonts/FuturaPT-Medium.woff') format('woff'),
        url('../fonts/FuturaPT-Medium.ttf') format('truetype'),
        url('../fonts/FuturaPT-Medium.svg#FuturaPT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Light.eot');
    src: url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Light.woff2') format('woff2'),
        url('../fonts/FuturaPT-Light.woff') format('woff'),
        url('../fonts/FuturaPT-Light.ttf') format('truetype'),
        url('../fonts/FuturaPT-Light.svg#FuturaPT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Heavy.eot');
    src: url('../fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Heavy.woff2') format('woff2'),
        url('../fonts/FuturaPT-Heavy.woff') format('woff'),
        url('../fonts/FuturaPT-Heavy.ttf') format('truetype'),
        url('../fonts/FuturaPT-Heavy.svg#FuturaPT-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPT-Book.eot');
    src: url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Book.woff2') format('woff2'),
        url('../fonts/FuturaPT-Book.woff') format('woff'),
        url('../fonts/FuturaPT-Book.ttf') format('truetype'),
        url('../fonts/FuturaPT-Book.svg#FuturaPT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

