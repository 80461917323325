.main-menu {
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
  left: 10%;
  margin: auto;
  z-index: 999;
}

.main-menu-item {
  border: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  border: 1px solid rgb(109, 108, 108);
  overflow: hidden;
}

.main-menu-item a {
  padding: 10px 0px;
  background-color: #6c3394;
  color: white;
  width: 100%;
  display: inherit;
}

.main-menu-item i {
  margin-right: 10px;
  font-size: 25px;
}

/* Auth menu */
.user-detail-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-detail span {
  font-weight: 900;
  font-size: 20px;
  margin-right: 10px;
}

.user-detail i {
  cursor: pointer;
}

.user-detail {
  position: relative;
}

.user-detail .firstname {
  text-transform: capitalize;
}
/* Auth menu */

/* Custom dropdown menu */
.custom-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  border-radius: 7px;
  padding: 5px 20px;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .main-menu {
    width: 100%;
    left: 0;
  }

  .menu-text {
    display: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-menu {
    width: 100%;
    left: 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
